import React from 'react';
import './CardContact.scss';
import Loading from '../assets/loading.svg';

const downloadContact = (vcfText, name) => {
    const element = document.createElement("a");
    const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.vcf`;
    document.body.appendChild(element);
    element.click();
    element.remove();
  };

function CardContact(props){
    const CreateVCard = (infos) => {
        var vCardsJS = require("vcards-js");
    
        //create a new vCard
        var vCard = vCardsJS();
    
        //set properties
        vCard.firstName = infos.firstName;
        vCard.lastName = infos.lastName;
        vCard.organization = infos.organization;
        vCard.workPhone = infos.workPhone;
        vCard.title = infos.title;
        vCard.email = infos.email;
    
        //get as formatted string
        return vCard.getFormattedString();
    };
    return(
        <div className='main-container'>
            <div className='logo'><img src='fraenkische-logo.svg' alt="Logo Fränkische"/></div>
            {
                props.loading ?
                    <img src={Loading} />
                :
                    <div className='form-container'>
                        <form>
                            <div className='row dual-input'>
                                <div className='input'>
                                    <label>Nom</label>
                                    <input type="text" disabled value={props.lastName} />
                                </div>
                                <div className='input'>
                                    <label>Prénom</label>
                                    <input type="text" disabled value={props.firstName} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='input'>
                                    <label>Adresse Email</label>
                                    <input type="text" disabled value={props.email} />
                                </div>
                            </div>
                            <div className='row dual-input'>
                                <div className='input'>
                                    <label>Téléphone</label>
                                    <input type="text" disabled value={props.workPhone} />
                                </div>
                                <div className='input'>
                                    <label>Poste occupé</label>
                                    <input type="text" disabled value={props.title} />
                                </div>
                            </div>
                            <button onClick={(event) => {
                                event.preventDefault();
                                downloadContact(CreateVCard(props), `${props.lastName}_${props.firstName}`)
                            }}>Enregistrer le contact</button>
                        </form>
                    </div>
            }
            
        </div>
    )
}

export default CardContact;