import './App.css';
import CardContact from './components/CardContact';
import React, { useEffect, useState } from 'react';

function App() {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [organization, setOrganization] = useState('');
  const [workPhone, setWorkPhone] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sku = urlParams.get('sku');
    document.title = `Contact - ${lastName} ${firstName}`;
    fetch(`${process.env.REACT_APP_API_URL}/public/shell/${process.env.REACT_APP_SHELL_ID}/${sku}`)
      .then(res => res.json())
      .then(
        (result) => {
          if (result){
            setEmail(result.email)
            setFirstName(result.firstname)
            setLastName(result.lastname)
            setWorkPhone(result.phone)
            setTitle(result.poste)
            setOrganization('Fränkische')
            setLoading(false)
          }
        },

        (error) => {
          console.log('error', error)
        }
      )
  }, []);
  
  return (
    <div className="App">
      <CardContact 
        lastName={lastName}
        firstName={firstName}
        organization={organization}
        workPhone={workPhone}
        title={title}
        email={email}
        loading={loading}
      />
    </div>
  );
}

export default App;
